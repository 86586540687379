<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--9 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <small>{{ $t("COMMON.RESET_PASSWORD") }}</small>
              </div>

              <base-alert type="danger" v-if="error">
                {{ error }}
              </base-alert>

              <base-alert type="success" v-if="resetPasswordEmailSent">
                {{ $t("COMMON.RESET_PASSWORD_SENT") }}
              </base-alert>

              <form
                class="needs-validation"
                @submit.prevent="handleSubmit()"
                v-if="!resetPasswordEmailSent"
              >
                <base-input
                  alternative
                  class="mb-3"
                  name="Email"
                  prepend-icon="ni ni-email-83"
                  placeholder="Email"
                  v-model="form.data.attributes.email"
                >
                </base-input>
                <validation-error :errors="apiValidationErrors.email" />

                <div class="text-center">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4"
                    :disabled="!form.data.attributes.email"
                  >
                    {{ $t("COMMON.SEND_PASSWORD_RESET_LINK") }}
                  </base-button>
                </div>
              </form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <router-link :to="{ name: 'Login' }" class="text-light">
                <small>{{ $t("COMMON.RETURN_TO_LOGIN") }}</small>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseAlert } from "@/components";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";

export default {
  layout: "AuthLayout",
  mixins: [formMixin],
  components: { ValidationError, BaseAlert },
  data() {
    return {
      form: {
        data: {
          type: "password-forgot",
          attributes: {
            email: "",
            redirect_url: process.env.VUE_APP_BASE_URL + "/password/email",
          },
        },
      },
      resetPasswordEmailSent: false,
      error: null,
    };
  },
  methods: {
    async handleSubmit() {
      this.error = null;
      try {
        await this.$store.dispatch("reset/forgotPassword", this.form.data);
        this.resetPasswordEmailSent = true;
      } catch (error) {
        this.error = this.$t("COMMON.RESET_PASSWORD_USER_NOT_FOUND");
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
